'use strict';

export class Homepage {
  constructor() {
    this.name = 'homepage';
    console.log('%s module', this.name.toLowerCase());

    $('.close').on('click', function(){
      $('#dialog').removeClass('show');
    })
   var loadFirstTime = localStorage.getItem("loadFirstTime");
   if(loadFirstTime == null){
     $('#dialog').addClass('show');
     localStorage.setItem('loadFirstTime', 'loaded');
   }
  }
}
