'use strict';

export class Stories {
  constructor() {
    this.name = 'stories';
    console.log('%s module', this.name.toLowerCase());
    $('li.list').on('hover', function () {
      // console.log($(this).attr('id'));
      $('.images img').attr('src', `../images/stories_hero_images/${$(this).attr('id')}.jpg`)
    })
  }
}
