"use strict";

export class StoriesTemplate {
  constructor() {
    this.name = "stories_template";
    console.log("%s module", this.name.toLowerCase());
    if ($("body").attr("page-type") == "stories") {
      var w = window.innerWidth;
      var size = w > 781 ? "big" : "small";
      var controller1 = new ScrollMagic.Controller();
      var controller2 = new ScrollMagic.Controller();
      var controller3 = new ScrollMagic.Controller();
      var controller4 = new ScrollMagic.Controller();
      var controller_less = new ScrollMagic.Controller();
      var controller6 = new ScrollMagic.Controller();
      var controller7 = new ScrollMagic.Controller();
      if (size === "big") {
        makeScrollMagic();
      }

      function makeScrollMagic() {
        var timeline1 = new TimelineMax();
        timeline1
          .from("#container1 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container1 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline1
          .from("#container1 .text_container .text_block_1", 1, {
            y: "50%",
            delay: 3,
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container1 .text_container .text_block_1", 4, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline1
          .from("#container1 .text_container .text_block_2", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container1 .text_container .text_block_2", 4, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });
        timeline1
          .from("#container1 .text_container .text_block_3", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container1 .text_container .text_block_3", 4, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });
        timeline1
          .from("#container1 .text_container .text_block_4", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container1 .text_container .text_block_4", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        if (window.innerWidth < 1700) {
          var firstscene = new ScrollMagic.Scene({
            triggerElement: "#trigger1",
            triggerHook: "0.1",
            duration: "700%",
          })
            .addTo(controller1)
            .setTween(timeline1) // add indicators (requires plugin)
            .setPin("#container1 .animated")
            .setClassToggle("#container1 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              firstscene.setPin("#container1 .animated");
              $("body").scrollTop(y);
            });
        } else if (window.innerWidth < 2000 && window.innerWidth > 1710) {
          var firstscene = new ScrollMagic.Scene({
            triggerElement: "#trigger1",
            triggerHook: "0.2",
            duration: "600%",
          })
            .addTo(controller1)
            .setTween(timeline1) // add indicators (requires plugin)
            .setPin("#container1 .animated")
            .setClassToggle("#container1 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              firstscene.setPin("#container1 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var firstscene = new ScrollMagic.Scene({
            triggerElement: "#trigger1",
            triggerHook: "0.2",
            duration: "500%",
          })
            .addTo(controller1)
            .setTween(timeline1) // add indicators (requires plugin)
            .setPin("#container1 .animated")
            .setClassToggle("#container1 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              firstscene.setPin("#container1 .animated");
              $("body").scrollTop(y);
            });
        }

        firstscene.addTo(controller1);

        // build scene
        var timeline2 = new TimelineMax();
        timeline2
          .from("#container2 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container2 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline2
          .from("#container2 .text_container .text_block_1", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container2 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline2
          .from("#container2 .text_container .text_block_2", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container2 .text_container .text_block_2", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        timeline2
          .from("#container2 .text_container .text_block_3", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container2 .text_container .text_block_3", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });

        timeline2
          .from("#container2 .text_container .text_block_4", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container2 .text_container .text_block_4", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        // timeline2.to('#container2 .text_container .text_block_5',1,{opacity: 0});
        // timeline.add(tween1).add(tween2);

        if (window.innerWidth < 1700) {
          var secondscene = new ScrollMagic.Scene({
            triggerElement: "#trigger2",
            triggerHook: "0.1",
            duration: "700%",
          })
            .addTo(controller2)
            .setTween(timeline2) // add indicators (requires plugin)
            .setPin("#container2 .animated")
            .setClassToggle("#container2 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              secondscene.setPin("#container2 .animated");
              $("body").scrollTop(y);
            });
        } else if (window.innerWidth < 2000 && window.innerWidth > 1710) {
          var secondscene = new ScrollMagic.Scene({
            triggerElement: "#trigger2",
            triggerHook: "0.2",
            duration: "600%",
          })
            .addTo(controller2)
            .setTween(timeline2) // add indicators (requires plugin)
            .setPin("#container2 .animated")
            .setClassToggle("#container2 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              secondscene.setPin("#container2 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var secondscene = new ScrollMagic.Scene({
            triggerElement: "#trigger2",
            triggerHook: "0.2",
            duration: "500%",
          })
            .addTo(controller2)
            .setTween(timeline2) // add indicators (requires plugin)
            .setPin("#container2 .animated")
            .setClassToggle("#container2 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              secondscene.setPin("#container2 .animated");
              $("body").scrollTop(y);
            });
        }
        secondscene.addTo(controller2);

        // build scene
        var timeline3 = new TimelineMax();
        timeline3
          .from("#container3 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container3 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline3
          .from("#container3 .text_container .text_block_1", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container3 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline3
          .from("#container3 .text_container .text_block_2", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container3 .text_container .text_block_2", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        timeline3
          .from("#container3 .text_container .text_block_3", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container3 .text_container .text_block_3", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        timeline3
          .from("#container3 .text_container .text_block_4", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container3 .text_container .text_block_4", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });

        if (window.innerWidth < 1700) {
          var thirdscene = new ScrollMagic.Scene({
            triggerElement: "#trigger3",
            duration: "700%",
            triggerHook: "0.1",
          })
            .addTo(controller3)
            .setTween(timeline3) // add indicators (requires plugin)
            .setPin("#container3 .animated")
            .offset(50)
            .setClassToggle("#container3 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              thirdscene.setPin("#container3 .animated");
              $("body").scrollTop(y);
            });
        } else if (window.innerWidth < 2000 && window.innerWidth > 1710) {
          var thirdscene = new ScrollMagic.Scene({
            triggerElement: "#trigger3",
            triggerHook: "0.2",
            duration: "600%",
          })
            .addTo(controller3)
            .setTween(timeline3) // add indicators (requires plugin)
            .setPin("#container3 .animated")
            .setClassToggle("#container3 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              thirdscene.setPin("#container3 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var thirdscene = new ScrollMagic.Scene({
            triggerElement: "#trigger3",
            triggerHook: "0.2",
            duration: "500%",
          })
            .addTo(controller3)
            .setTween(timeline3) // add indicators (requires plugin)
            .setPin("#container3 .animated")
            .setClassToggle("#container3 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              thirdscene.setPin("#container3 .animated");
              $("body").scrollTop(y);
            });
        }

        thirdscene.addTo(controller3);

        // build scene
        var timeline4 = new TimelineMax();
        timeline4
          .from("#container4 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container4 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline4
          .from("#container4 .text_container .text_block_1", 1, {
            y: "10%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container4 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline4
          .from("#container4 .text_container .text_block_2", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container4 .text_container .text_block_2", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        timeline4
          .from("#container4 .text_container .text_block_3", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container4 .text_container .text_block_3", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });
        timeline4
          .from("#container4 .text_container .text_block_4", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container4 .text_container .text_block_4", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeNone,
          });

        if (window.innerWidth < 1700) {
          var fourthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger4",
            duration: "700%",
            triggerHook: "0.1",
          })
            .addTo(controller4)
            .setTween(timeline4) // add indicators (requires plugin)
            .setPin("#container4 .animated")
            .setClassToggle("#container4 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              fourthscene.setPin("#container4 .animated");
              $("body").scrollTop(y);
            });
        } else if (window.innerWidth < 2000 && window.innerWidth > 1710) {
          var fourthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger4",
            triggerHook: "0.2",
            duration: "600%",
          })
            .addTo(controller4)
            .setTween(timeline4) // add indicators (requires plugin)
            .setPin("#container4 .animated")
            .setClassToggle("#container4 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              fourthscene.setPin("#container4 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var fourthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger4",
            triggerHook: "0.2",
            duration: "430%",
          })
            .addTo(controller4)
            .setTween(timeline4) // add indicators (requires plugin)
            .setPin("#container4 .animated")
            .setClassToggle("#container4 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              fourthscene.setPin("#container4 .animated");
              $("body").scrollTop(y);
            });
        }

        fourthscene.addTo(controller4);

        var timeline5 = new TimelineMax();
        timeline5
          .from("#container5 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container5 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline5
          .from("#container5 .text_container .text_block_1", 1, {
            y: "10%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container5 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });
        if (window.innerWidth < 1700) {
          var fifthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger5",
            duration: "200%",
            triggerHook: "0.1",
          })
            .addTo(controller_less)
            .setTween(timeline5) // add indicators (requires plugin)
            .setPin("#container5 .animated")
            .setClassToggle("#container5 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              fifthscene.setPin("#container5 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var fifthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger5",
            triggerHook: "0.2",
            duration: "140%",
          })
            .addTo(controller_less)
            .setTween(timeline5) // add indicators (requires plugin)
            .setPin("#container5 .animated")
            .setClassToggle("#container5 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              fifthscene.setPin("#container5 .animated");
              $("body").scrollTop(y);
            });
        }

        fifthscene.addTo(controller_less);
        /// New CONTROLLER FOR TWO BLOCKS

        var timeline7 = new TimelineMax();
        timeline7
          .from("#container7 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container7 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline7
          .from("#container7 .text_container .text_block_1", 1, {
            y: "10%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container7 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });
        if (window.innerWidth < 1700) {
          var twoblockscene = new ScrollMagic.Scene({
            triggerElement: "#trigger7",
            duration: "350%",
            triggerHook: "0.1",
          })
            .addTo(controller7)
            .setTween(timeline7) // add indicators (requires plugin)
            .setPin("#container7 .animated")
            .setClassToggle("#container7 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              twoblockscene.setPin("#container7 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var twoblockscene = new ScrollMagic.Scene({
            triggerElement: "#trigger7",
            triggerHook: "0.2",
            duration: "220%",
          })
            .addTo(controller7)
            .setTween(timeline7) // add indicators (requires plugin)
            .setPin("#container7 .animated")
            .setClassToggle("#container7 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              twoblockscene.setPin("#container7 .animated");
              $("body").scrollTop(y);
            });
        }

        twoblockscene.addTo(controller7);

        var timeline6 = new TimelineMax();
        timeline6
          .from("#container6 .text_container .title", 1, {
            y: "50%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container6 .text_container .title", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });

        timeline6
          .from("#container6 .text_container .text_block_1", 1, {
            y: "10%",
            opacity: 1,
            ease: Linear.easein,
          })

          .to("#container6 .text_container .text_block_1", 1, {
            y: "-150%",
            opacity: 0.8,
            ease: Linear.easeout,
          });
        if (window.innerWidth < 1700) {
          var sixthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger6",
            duration: "600%",
            triggerHook: "0.1",
          })
            .addTo(controller6)
            .setTween(timeline6) // add indicators (requires plugin)
            .setPin("#container6 .animated")
            .setClassToggle("#container6 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              sixthscene.setPin("#container6 .animated");
              $("body").scrollTop(y);
            });
        } else {
          var sixthscene = new ScrollMagic.Scene({
            triggerElement: "#trigger6",
            triggerHook: "0.2",
            duration: "450%",
          })
            .addTo(controller6)
            .setTween(timeline6) // add indicators (requires plugin)
            .setPin("#container6 .animated")
            .setClassToggle("#container6 .animated", "brighten")
            .on("enter", function(event) {
              var y = $("body").scrollTop();
              sixthscene.setPin("#container6 .animated");
              $("body").scrollTop(y);
            });
        }

        sixthscene.addTo(controller6);
      }

      $(".show_all_stories a").on("mouseenter", function() {
        $(this)
          .find("span")
          .remove();
        $(this).prepend(
          '<span class="icon-arrow-white"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>'
        );
      });
      $(".show_all_stories a").on("mouseleave", function() {
        $(this)
          .find("span")
          .remove();
        $(this).prepend('<span class="icon-arrow-left"></span>');
      });
      $(window).resize(function() {
        w = window.innerWidth;
        var newSize = w > 781 ? "big" : "small";
        if (newSize != size) {
          size = newSize;
          if (newSize === "small") {
            controller1.destroy(true);
            controller2.destroy(true);
            controller3.destroy(true);
            controller4.destroy(true);
            controller_less.destroy(true);
            controller6.destroy(true);
          } else {
            console.log("The screen is now large - ScrollMagic is active.");
            makeScrollMagic();
          }
        }
      });
    }
    $(".pagination_text .text_container").slick({
      autoplay: false,
      infinite: false,
      adaptiveHeight: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<span class="icon-arrow-left"></span>',
      nextArrow: '<span class="icon-arrow-right"></span>',
    });
    $(".profile_wrapper.slider .section_blocks").slick({
      autoplay: false,
      infinite: false,
      adaptiveHeight: false,
      dots: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<span class="icon-arrow-left"></span>',
      nextArrow: '<span class="icon-arrow-right"></span>',
    });
  }
}
