// Main javascript entry point
// Should handle bootstrapping/starting application

"use strict";

import $ from "jquery";
import { Homepage } from "../_modules/homepage/homepage";
import { Connections } from "../_modules/connections/connections";
import { LoadTemplate } from "../_modules/loadtemplate/loadTemplate";
import { Header } from "../_modules/header/header";
import { Stories } from "../_modules/stories/stories";
import { About } from "../_modules/about/about";
import { SelectedHighlights } from "../_modules/selected_highlights/selected_highlights";
import { StoriesTemplate } from "../_modules/stories_template/stories_template";
import { MapAttributes } from "../_modules/map_attributes/map_attributes";

$(() => {
  new Stories();
  new Homepage();
  new SelectedHighlights();
  new Header();
  new About();
  new LoadTemplate();
  new StoriesTemplate();
  new MapAttributes();
  new Connections();
});
