'use strict';


export  class SelectedHighlights {
  constructor() {
    this.name = 'selected_highlights';
    console.log('%s module', this.name.toLowerCase());
     $('.selected_highlights .row').slick({
      autoplay: false,
      infinite: false,
      adaptiveHeight: false,
      dots: true,
      slidesToShow: 2,
      slidesToScroll:1,
       prevArrow:'<span class="icon-chevron-left"></span',
      nextArrow:'<span class="icon-chevron-right"></span',
     });
      
     $('.individual_list').each(function(){ 
        
        var text_limit =$(this).find('.text_restrict').text();
       var hidden_text = $(this).find('.text_restrict').text().substring(150).toString();
       $(this).find('.text_restrict').html(text_limit.substring(0,150)+ '...'+ '<div class="hidden-text">'+hidden_text+'</div>'+ '<br/>' + '<div class="more">View More</div>');
     
     }
     )
       $('.individual_list .text_restrict .more').on('click',function(){
        var text_limit =$(this).parent('.text_restrict').text();
        var hidden = $(this).siblings('.hidden-text').text();
          $(this).parent('.text_restrict').text(text_limit.substring(0,150) + hidden.toString());
       })
      $('.theses_information').slick({
      autoplay: false,
      infinite: false,
      adaptiveHeight: false,
      dots: true,
       prevArrow:'<span class="icon-chevron-left"></span',
      nextArrow:'<span class="icon-chevron-right"></span',
     });
      $('.grants_information').slick({
      autoplay: false,
      infinite: false,
      adaptiveHeight: false,
      dots: true,
       prevArrow:'<span class="icon-chevron-left"></span',
      nextArrow:'<span class="icon-chevron-right"></span',
     });
    
  $(function(){
	 var lastScrollTop = 0, delta = 5;
	 $(document).scroll(function(){
		 var nowScrollTop = $(this).scrollTop();
		 if(Math.abs(lastScrollTop - nowScrollTop) >= delta){
		 	if (nowScrollTop < lastScrollTop){
         $('.back_to_top .change_position').addClass('positioning');
      }
      else{
        $('.back_to_top .change_position').removeClass('positioning');
      }
		 lastScrollTop = nowScrollTop;
		 }
    
	 });
 });
 $('.back_to_top .change_position a').on('mouseenter', function(){
      $(this).find('span').remove()
      $(this).prepend('<span class="icon-arrow-up-filled"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>')
    })
    $('.back_to_top .change_position a').on('mouseleave', function(){
     $(this).find('span').remove();
      $(this).prepend('<span class="icon-arrow-up"></span>');
    })  
}
  
}
