"use strict";
export class MapAttributes {
  constructor() {
    if ($("body").attr("page-type") == "connections") {
      this.name = "map_attributes";
      var map;
      var researchmarker;
      var peoplemarker;
      var outreachmarker;
      var languagesmarker;
      var activepinmarkers;
      var activeresearchmarker;
      var activelanguagesmarker;
      var activeoutreachmarker;
      var activepeoplemarker;
      var blackIcon;
      var marker;
      var pathline;
      var polylines = [];
      // var allBounds = L.latLngBounds();
      // var activeBounds = L.latLngBounds();
      var allBoundsList = [];
      var activeBoundsList = [];
      initialiseMap();
      initialiseLayers();
      initialiseIcons();
      loadMarkers();
      bindActions();

      function initialiseMap() {
        // Set the map object
        map = new L.Map("map", {
          center: [-10.505, 128],
          zoom: 5,
          // layers: [osm],
          minZoom: 2,
          maxBounds: [[-90, -180], [90, 180]],
          maxBoundsViscosity: 1.0,
        });

        // Add the base layer with the tile
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
          {
            attribution:
              '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: "abcd",
            maxZoom: 20,
          }
        ).addTo(map);
      }

      function initialiseLayers() {
        researchmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = researchmarker._defaultIconCreateFunction(cluster);
            icon.options.className += "research-group";
            return icon;
          },
        });
        languagesmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = languagesmarker._defaultIconCreateFunction(cluster);
            icon.options.className += "language-group";
            return icon;
          },
        });
        outreachmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = peoplemarker._defaultIconCreateFunction(cluster);
            icon.options.className += "outreach-group";
            return icon;
          },
        });
        peoplemarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = peoplemarker._defaultIconCreateFunction(cluster);
            icon.options.className += "people-group";
            return icon;
          },
        });

        activepinmarkers = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = activepinmarkers._defaultIconCreateFunction(cluster);
            icon.options.className += "active-pin-group";
            return icon;
          },
        });

        activeresearchmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = activeresearchmarker._defaultIconCreateFunction(cluster);
            icon.options.className += "research-group selected";
            return icon;
          },
        });
        activelanguagesmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = activelanguagesmarker._defaultIconCreateFunction(
              cluster
            );
            icon.options.className += "language-group selected";
            return icon;
          },
        });
        activeoutreachmarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = activeoutreachmarker._defaultIconCreateFunction(cluster);
            icon.options.className += "outreach-group selected";
            return icon;
          },
        });
        activepeoplemarker = new L.MarkerClusterGroup({
          singleMarkerMode: true,
          iconCreateFunction: function (cluster) {
            var icon = activepeoplemarker._defaultIconCreateFunction(cluster);
            icon.options.className += "people-group selected";
            return icon;
          },
        });
      }

      function initialiseIcons() {
        blackIcon = new L.Icon({
          iconUrl:
            "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-black.png",
          shadowUrl:
            "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });
      }

      function loadMarkers() {
        var urls = [
          "/api?category=research",
          "/api?category=languages",
          "/api?category=outreach",
          "/api?category=people",
          "/api?category=node",
        ];
        var reqPromises = urls.map(function (url) {
          return $.ajax({
            url: url,
            dataType: "JSON",
            type: "GET",
          });
        });

        allBoundsList = [];

        Promise.all(reqPromises).then(function (res) {
          var param = res.map(function (item) {
            // // console.log(item);
            return item;
          });

          addMarkerToLayer(param[0], researchmarker, "research");
          addMarkerToLayer(param[1], languagesmarker, "languages");
          addMarkerToLayer(param[2], outreachmarker, "outreach");
          addMarkerToLayer(param[3], peoplemarker, "people");

          $.each(param[4], function (i, d) {
            var node_marker = L.marker([d.coordinates.lat, d.coordinates.lng], {
              icon: blackIcon,
              alt: "Data",
              data: d,
            });

            allBoundsList.push([d.coordinates.lat, d.coordinates.lng]);

            node_marker.bindPopup(d.node);
            node_marker.on("click", function (e) {
              loadTemplate(e.target.options.data, "node");
              $(".filter_map .search").click();
              $(".filter_wrapper").hide();
            });
            node_marker.addTo(map);
          });

          map.invalidateSize();
          setTimeout(function () {
            map.invalidateSize();
            map.fitBounds(allBoundsList);
          }, 300);
        });
      }

      function addMarkerToLayer($data, $layer, $category) {
        $.each($data, function (i, d) {
          marker = L.marker([d.coordinates.lat, d.coordinates.lng], {
            alt: "Data",
            data: d,
          });

          allBoundsList.push([d.coordinates.lat, d.coordinates.lng]);

          marker.bindPopup(d.project);

          marker.on("click", function ($pin_clicked) {
            loadRelevantPins($pin_clicked, $layer, $category);

            // loadTemplate(e.target.options.data, $category);
            // $('.filter_map .search').click();
            // $('.filter_wrapper').hide();
          });

          $layer.addLayer(marker);
        });

        map.addLayer($layer);
      }

      function addSingleMarkerToLayer($data, $layer, $category, $pin) {
        var $cordinate = [$data.coordinates.lat, $data.coordinates.lng];
        var marker = L.marker($cordinate, {
          alt: "Data",
          data: $data,
        });

        // marker.bindPopup(d.project)

        marker.on("click", function ($pin_clicked) {
          loadRelevantPins($pin_clicked, $layer, $category);
          // loadTemplate(e.target.options.data, $category);
          $(".filter_map .search").click();
          $(".filter_wrapper").hide();
        });
        $layer.addLayer(marker);

        activeBoundsList.push($cordinate);

        if (polylines.length > 0) {
          polylines.forEach(function (item) {
            map.removeLayer(item);
          });
        }

        console.log(activeBoundsList);
        var listtojoin;

        listtojoin = activeBoundsList;
        console.log(listtojoin);
        var $centerpoint;
        $.each(listtojoin, function (i, d) {
          console.log(d[0], d[1]);
          if ($pin.hasOwnProperty("latlng")) {
            $centerpoint = $pin.latlng;
          } else {
            $centerpoint = new L.LatLng(
              $pin.coordinates.lat,
              $pin.coordinates.lng
            );
          }
          var centreLocation = [new L.LatLng(d[0], d[1]), $centerpoint];
          console.log(centreLocation);
          pathline = L.polyline(centreLocation, {
            color: "green",
            opacity: 1,
            weight: 1,
          });
          map.addLayer(pathline);
          polylines.push(pathline);
        });

        map.addLayer($layer);
      }

      function loadTemplate(response, category) {
        // // console.log(response);

        var element = $(".search_container");
        var tempElement = $("<div/>");
        if (response.length < 1) return;
        var title, subtitle, description, image;

        //check archieve length append only if archieve is present in response
        var archive = response["archive"];
        var numbers_of_archieve = $("<div />");
        if (!archive) numbers_of_archieve = $("<div />");
        if (archive) {
          numbers_of_archieve.append("<h3>Archive Collections</h3>");
        }

        if (archive && archive.length > 1) {
          if (Array.isArray(archive)) {
            $.each(archive, function (i, v) {
              numbers_of_archieve.append(
                `<a target= '_blank' title=${JSON.stringify(
                  getDescription(v)
                )} href=${JSON.stringify(getURL(v))} class="${category}">${i +
                1}</a>`
              );
            });
          } else {
            numbers_of_archieve.append(
              `<a target="_blank" title="${getDescription(
                archive
              )}" href="${getURL(archive)}" class= ${category}>1</a>`
            );
          }
        }

        // Other links sections
        // SHOW OTHER LINK TITLE ONLY WHEN THE DATA CONSIST OF LINK (ANY LINK )
        var link_title = $("<div />");
        if (
          response["link_website"].length > 0 ||
          response["link_youtube"].length > 0 ||
          response["link_publication"].length > 0 ||
          (response["link_research_profile"] &&
            response["link_researcher_profile"].length > 0)
        ) {
          link_title.append("<h3>Other Links</h3>");
        } else {
          link_title.append("");
        }

        //DIV RELATED TO RELATED RESEARCH IF NO LIST OF LENGTH IS LESS THAN 1 DO NOTHING; ELSE RENDER DIV
        var related_research = $("<div />");
        var research_list = response["research"];
        if (!research_list || research_list.length < 1) {
          related_research.append("");
        }
        if (research_list && research_list.length > 1) {
          related_research.append(
            '<h2 class = "title" > Research Projects </h2>'
          );
          // console.log('research total number is ', research_list.length);
          //CHECK IF RESEARCH LIST IS ON ARRAY . IF ARRAY LOOP DATA AND ASSIGN DATA TO ITS LIST LIKE PREVIOUS
          if (Array.isArray(research_list)) {
            console.clear();
            console.log("Debug research list", research_list);
            $.each(research_list, function (i, v) {
              // console.log('I am loop number', v);
              getRelatedCategories("research", v, function (data) {
                var data_item = data.length > 0 ? data[0] : "";
                var li = $("<li/>").html(data_item["research_list"]);
                console.log(li);
                li.attr("data-info", JSON.stringify(data_item));
                li.attr("category", "research");
                $(".related_category_item#research ul").append(li);
                // console.log("Fetching data");
                $(".related_category_item#research ul li")
                  .sort(function (a, b) {
                    var aText = $(a)
                      .text()
                      .toLowerCase(),
                      bText = $(b)
                        .text()
                        .toLowerCase();
                    return aText < bText ? -1 : aText > bText ? 1 : 0;
                  })
                  .appendTo(".related_category_item#research ul");
              });
            });
            console.log("Sorting data");


          } else {
            //IF RESEARCH LIST IS SINGLE ITEM . JUST DISPLAY THE LIST
            // console.log("Research list single item", research_list);
            getRelatedCategories("research", research_list, function (data) {
              // // console.log("Research list array", data);
              var data_item = data.length > 0 ? data[0] : "";
              var li = $("<li/>").html(data_item["research_list"]);
              li.attr("data-info", JSON.stringify(data_item));
              li.attr("category", "research");
              $(".related_category_item#research ul").append(li);
            });
          }

        }


        //DIV RELATED TO RELATED PEOPLE IF NO LIST OR LENGTH IS LESS THAN 1 DO NOTHING; ELSE RENDER DIV
        var related_people = $("<div />");
        var people_list = response["people"];
        if (!people_list || people_list.length < 1) {
          related_people.append("");
        }
        if (people_list && people_list.length > 1) {
          related_people.append('<h2 class = "title" > People </h2>');
          // console.log('poeple total number is ', people_list.length);
          //CHECK IF PEOPLE LIST IS ON ARRAY . IF ARRAY LOOP DATA AND ASSIGN DATA TO ITS LIST LIKE PREVIOUS
          if (Array.isArray(people_list)) {
            $.each(people_list, function (i, v) {
              getRelatedCategories("people", v, function (data) {
                // console.table("people list array list data", 'Total number', i, data,);
                var data_item = data.length > 0 ? data[0] : "";
                var li = $("<li/>").html(data_item["name"]);
                var role = $("<span />").html(data_item["coedl_role"]);
                li.attr("data-info", JSON.stringify(data_item));
                li.attr("category", "people");
                $(".related_category_item#people ul").append(li);
                li.append(role);
                $(".related_category_item#people ul li")
                  .sort(function (a, b) {
                    var aText = $(a)
                      .text()
                      .toLowerCase(),
                      bText = $(b)
                        .text()
                        .toLowerCase();
                    return aText < bText ? -1 : aText > bText ? 1 : 0;
                  }).appendTo(".related_category_item#people ul");
                // var text =
                //   $(".related_category_item#people ul li")
                //     .text()
                //     .indexOf("??") >= 0;
                // if (text) {
                //   $(".related_category_item#people ul li").text(function(
                //     index,
                //     text
                //   ) {
                //     return text.replace("??", String.fromCharCode(10013));
                //   });
                // }
              });
            });
          } else {
            //IF PEOPLE LIST IS SINGLE ITEM . JUST DISPLAY THE LIST
            // console.log("People list single item", people_list);
            getRelatedCategories("people", people_list, function (data) {
              // console.log("people list array", data);
              var data_item = data.length > 0 ? data[0] : "";
              var li = $("<li/>").html(data_item["name"]);
              var role = $("<span />").html(data_item["coedl_role"]);
              li.attr("data-info", JSON.stringify(data_item));
              li.attr("category", "people");
              $(".related_category_item#people ul").append(li);
              $(".related_category_item#people ul").append(role);
            });
          }
        }

        //DIV RELATED TO RELATED Language IF NO LIST OR LENGTH IS LESS THAN 1 DO NOTHING; ELSE RENDER DIV
        var related_language = $("<div />");
        var language_list = response["language"];
        if (!language_list || language_list.length < 1) {
          related_language.append("");
        }
        if (language_list && language_list.length > 1) {
          related_language.append('<h2 class = "title" > Language </h2>');
          // console.log('language total number is ', language_list.length);
          //CHECK IF language LIST IS ON ARRAY . IF ARRAY LOOP DATA AND ASSIGN DATA TO ITS LIST LIKE PREVIOUS
          if (Array.isArray(language_list)) {
            // console.log("language list array", language_list);
            $.each(language_list, function (i, v) {
              getRelatedCategories("languages", v, function (data) {
                var data_item = data.length > 0 ? data[0] : "";
                var li = $("<li/>").html(data_item["languagename"]);
                var country = $("<span />").html(data_item["country"]);
                li.attr("data-info", JSON.stringify(data_item));
                li.attr("category", "languages");
                $(".related_category_item#languages ul").append(li);
                li.append(country);
                $(".related_category_item#languages ul li")
                  .sort(function (a, b) {
                    var aText = $(a)
                      .text()
                      .toLowerCase(),
                      bText = $(b)
                        .text()
                        .toLowerCase();
                    return aText < bText ? -1 : aText > bText ? 1 : 0;
                  }).appendTo(".related_category_item#languages ul");

              });

            });

          } else {
            //IF language LIST IS SINGLE ITEM . JUST DISPLAY THE LIST
            // console.log("language list single item", language_list);
            getRelatedCategories("languages", language_list, function (data) {
              // console.log("language list single list data", 1, data);
              var data_item = data.length > 0 ? data[0] : "";
              var li = $("<li/>").html(data_item["languagename"]);
              var country = $("<span />").html(data_item["country"]);
              li.attr("data-info", JSON.stringify(data_item));
              li.attr("category", "languages");
              $(".related_category_item#languages ul").append(li);
              $(".related_category_item#languages ul").append(country);
            });
          }
        }

        //DIV RELATED TO RELATED Outreach IF NO LIST OR LENGTH IS LESS THAN 1 DO NOTHING; ELSE RENDER DIV
        var related_outreach = $("<div />");
        var outreach_list = response["outreach"];
        if (!outreach_list || outreach_list.length < 1) {
          related_outreach.append("");
        }
        if (outreach_list && outreach_list.length > 1) {
          $(related_outreach).append('<h2 class = "title" > Outreach </h2>');
          // console.log('outreach total number is ', outreach_list.length);
          //CHECK IF outreach LIST IS ON ARRAY . IF ARRAY LOOP DATA AND ASSIGN DATA TO ITS LIST LIKE PREVIOUS
          if (Array.isArray(outreach_list)) {
            // console.log("outreach list array", outreach_list);
            $.each(outreach_list, function (i, v) {
              getRelatedCategories("outreach", v, function (data) {
                // console.log('Outreach data to be displayed', data);
                var data_item = data.length > 0 ? data[0] : "";
                var li = $("<li/>").html(data_item["outreach_list_display"]);
                li.attr("data-info", JSON.stringify(data_item));
                li.attr("category", "outreach");
                $(".related_category_item#outreach ul").append(li);
                $(".related_category_item#outreach ul li")
                  .sort(function (a, b) {
                    var aText = $(a)
                      .text()
                      .toLowerCase(),
                      bText = $(b)
                        .text()
                        .toLowerCase();
                    return aText < bText ? -1 : aText > bText ? 1 : 0;
                  }).appendTo(".related_category_item#outreach ul");
              });
            });
          } else {
            //IF outreach LIST IS SINGLE ITEM . JUST DISPLAY THE LIST
            // console.log("Outreach list array list data", 'Total number', 1, outreach_list);
            getRelatedCategories("outreach", outreach_list, function (data) {
              // console.log("outreach list items single", data);
              var data_item = data.length > 0 ? data[0] : "";
              var li = $("<li/>").html(data_item["outreach_list_display"]);
              li.attr("data-info", JSON.stringify(data_item));
              li.attr("category", "outreach");
              $(".related_category_item#outreach ul").append(li);
            });
          }
        }

        //Related Stories Categories
        var related_stories = $("<div />");
        var stories_list = response["stories"];
        if (!stories_list || stories_list.length < 1) {
          related_stories.append("");
        }
        if (stories_list && stories_list.length > 1) {
          $(related_stories).append(`<div class="related_stories_title">
         <h2>Related Stories </h2> 
          <span class = "icons">
          <span class = "icon-chevron-left"> </span> 
          <span class = "icon-chevron-right"> </span>
           </span> </div>`);
          // console.log('Stories length is ', stories_list.length);
          $(related_stories).append('<div class = "list_stories">');
          // // console.log(site.data.stories[0].url);
          //CHECK IF stories LIST IS ON ARRAY . IF ARRAY LOOP DATA AND ASSIGN DATA TO ITS LIST LIKE PREVIOUS
          if (Array.isArray(stories_list)) {
            // console.log("Stories list array", stories_list);
            $.each(stories_list, function (i, v) {
              console.log(v);
              $.getJSON("../data/stories.json?v=15", function (json) {
                console.log("Reading stories", json.stories.story, v);
                $.each(json.stories.story, function (i, d) {
                  console.log(d);
                  if (d["key"].includes(v)) {
                    console.log("Matching keys found");
                    $(".list_stories").append(`<a href="${d.data["link"]
                      }"><div class="story"><img src="${d.data["image"]
                      }" alt="${v}" />
                  <h3>${d.data["title"]}</h3></div></a>`);
                  }
                });
              });
            });
          } else {
            //IF Stories LIST IS SINGLE ITEM . JUST DISPLAY THE LIST
            // console.log("Stories list array list data", 'Total number', 1, stories_list);
            $.getJSON("../data/stories.json?v=12", function (json) {
              $.each(json.stories.story, function (i, d) {
                if (d["key"].includes(stories_list)) {
                  // console.log('only one Matching keys found');
                  $(".list_stories").append(`<a href="${d.data["link"]
                    }"><div class="story"><img src="${d.data["image"]
                    }" alt="${stories_list}" />
                <h3>${stories_list}</h3></div></a>`);
                }
              });
            });
          }
        }

        //Images for people
        var people_image = $("<div />");
        if (!response["photo"] || response["photo"].length < 0) {
          // console.log('not_found_photo');
          people_image.append("");
        } else if (response["photo"].length > 0) {
          // console.log('found_photo');
          people_image.append(
            `<img src='../images/peoples_images/${response["photo"]}' alt='${response["photo"]
            }'>`
          );
        }

        // Images for campus
        var college_logo = $("<div />");
        if (!response["logo"] || response["logo"].length < 0) {
          // console.log('not_found_photo');
          people_image.append("");
        } else if (response["logo"].length > 0) {
          // console.log('found_photo');
          people_image.append(
            `<img src='../images/nodes_logo/${response["logo"]
            }' class='fill' alt='${response["logo"]}'>`
          );
        }

        //OTHER LINK WEBSITE SECTION ; SHOW ONLY IF WEBSITE IS PRESENT
        var website = $("<div />");
        if (!response["link_website"] || response["link_website"].length < 0) {
          website = $("<div />");
        } else if (response["link_website"].length > 0) {
          website.append(`<a class= ${category} href=${getURL(
            response["link_website"]
          )} target="_blank">
                            <span class="icon-globe"></span>
                         </a>`);
        }
        //OTHER LINK USER SECTION ; SHOW ONLY IF USER IS PRESENT
        var user = $("<div />");
        if (
          !response["link_researcher_profile"] ||
          response["link_researcher_profile"].length < 0
        ) {
          user = $("<div />");
        } else if (response["link_researcher_profile"].length > 0) {
          user.append(`<a class= ${category} href=${getURL(
            response["link_researcher_profile"]
          )} target="_blank">
                            <span class="icon-user"></span>
                         </a>`);
        }
        //OTHER LINK PUBLICATION SECTION ; SHOW ONLY IF PUBLICATION IS PRESENT
        var publication = $("<div />");
        if (
          !response["link_publication"] ||
          response["link_publication"].length < 0
        ) {
          publication = $("<div />");
        } else if (response["link_publication"].length > 0) {
          publication.append(`<a class= ${category} href=${getURL(
            response["link_publication"]
          )} target="_blank">
                            <span class="icon-document-duplicate"></span>
                         </a>`);
        }

        //OTHER LINK YOUTUBE LINL SECTION ; SHOW ONLY IF YOUTUBE LINL IS PRESENT
        var youtube = $("<div />");
        if (!response["link_youtube"] || response["link_youtube"].length < 0) {
          youtube = $("<div />");
        } else if (response["link_youtube"].length > 0) {
          youtube.append(`<a class= ${category} href=${getURL(
            response["link_youtube"]
          )} target="_blank">
                            <span class="icon-youtube"></span>
                         </a>`);
        }

        // THE TITLE IS DIFFERENT ON DIFFERNT CATEGORIES . CHANGE TITLE SUBTITLE AND DESCRIPTION WITH API HEADINGS
        if (category === "research") {
          title = response["project"];
          subtitle = response["field_site_location"];
          description = response["description"];
          var link = description.substring(
            description.indexOf("(http") + 1,
            description.lastIndexOf(")")
          );
          var text_to_replace = link;
          var a_tag = link;
        } else if (category === "languages") {
          title = response["languagename"];
          subtitle = response["country"];
          description = response["description"];
          var link = description.substring(
            description.indexOf("(http") + 1,
            description.lastIndexOf(")")
          );

          var text_to_replace = link;
          var a_tag = link;

        } else if (category === "people") {
          title = response["name"];
          subtitle = response["coedl_role"];
          image = response["photo"];
          description = response["bio_blurb"];
          var link = description.substring(
            description.indexOf("(") + 1,
            description.indexOf(")")
          );
          var a_tag = description.substring(
            description.indexOf(" - ") + 1,
            description.indexOf("(")
          );
          var text_to_replace = description.substring(
            description.indexOf(" - ") + 1,
            description.indexOf(")") + 1
          );
        } else if (category === "outreach") {
          title = response["outreach_activity_description"];
          // subtitle = response["outreach_location"];
          console.log(response["outreach_location"]);
          console.log(
            decodeURIComponent(unescape(response["outreach_location"]))
          );
          console.log(
            unescape(encodeURIComponent(response["outreach_location"]))
          );
          subtitle = response["outreach_location"];
          description = response["impact"];
          var link = description.substring(
            description.indexOf("(http") + 1,
            description.indexOf(")")
          );
          var a_tag = link;
          var text_to_replace = link;
        } else if (category === "node") {
          title = response["node"];
          subtitle = response["field_site_location"];
          description = response["description"];
        }
        try {
          //THE MAIN APPEND SECTION // JUST RENDER THE VARIABLE FROM ABOVE
          tempElement.append(`
            <div class="output_results ${category}">
            <div class="container">
              <div class="close">

             <p>Close</p>
             <span class="icon-cross"></span>
            </div>
            <div class="results_container">

             <div class="results_title">
                <!-- change color inherited from parent -->
                <div class="image ${category}" >
                 ${people_image.html()}
                 ${college_logo.html()}
                </div>
                <div class="result_title">
                 <h4 class="categories red">${category}</h4>
                 <h1 class="title red"> ${title.indexOf("??") > 0
              ? (title = title.replace("??", String.fromCharCode(10013)))
              : (title = title)
            }</h1>
                  <h2 class="location">${subtitle}</h2>
                </div>
             </div>
             <div class="result_information">
                <p>${description.indexOf("(http") > 0
              ? (description = description.replace(
                text_to_replace,
                `<a href=${link} target="_blank">${a_tag}</a>`
              ))
              : (description = description)
            }</p>
                <div class="archieve">
                  <div class="numbers_of_archieve">${numbers_of_archieve.html()}</div>
                </div>
                <div class="links">
                   ${link_title.html()}
                   <div class="links_container">
                      <div class="website">

                      ${website.html()}
                      </div>
                      <div class="user">
                        ${user.html()}
                      </div>
                      <div class="document">
                         ${publication.html()}
                      </div>
                      <div class="youtube">
                         ${youtube.html()}
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
       <div class="show_related_stories">

              ${related_stories.html()}
       </div>
       <div class="next_category">
          <!-- for loop on each related content -->
          <div class="related_category_item" id="research">
            <ul>
             <!-- //foreach loop here -->
            ${related_research.html()}
            </ul>
          </div>
          <div class="related_category_item" id="people">
             <ul>
             <!-- //foreach loop here -->
            ${related_people.html()}
            </ul>
          </div>

           <div class="related_category_item" id="languages">
           <ul>
            ${related_language.html()}
            </ul>
          </div>
          <div class="related_category_item" id="outreach">
          <ul>
            ${related_outreach.html()}
            </ul>
          </div>
       </div>

    </div>
          `);
          element.find(".searched_results").html(tempElement.html());
          if ($("#check").is(":checked") || $(window).innerWidth() < 767) {
            $(".filter_wrapper").hide();
            $(".searched_results").css("display", "block");
          }
          $(".searched_results").css("display", "block");
          $("#filter_list").css("display", "block");
          $("#map").addClass("slide_open");

          $(".output_results .container .close").on("click", function () {
            $(".searched_results").css("display", "none");
            $("#filter_list").css("display", "block");
            $(".filter_wrapper").show();
            // $(".searched_results").css("display", "none");
            //$(".filter_wrapper").css("display", "block");
            //$("#filter_list").css("display", "none");
            $("#map").removeClass("slide_open");
            map.invalidateSize();
            killLoadedPins();
            loadMarkers();
            if (polylines.length > 0) {
              polylines.forEach(function (item) {
                map.removeLayer(item);
              });
            }
          });

          //SLICK FOR RELATED STORIES
          setTimeout(function () {
            $(".show_related_stories .list_stories").slick({
              slidesToShow: 2,
              slidesToScroll: 1,
              autoplay: false,
              infinite: false,
              dots: true,
              prevArrow: $(".icons .icon-chevron-left"),
              nextArrow: $(".icons .icon-chevron-right"),
              responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          }, 500);
        } catch (e) {
          // console.log('Invalid json response', e)
        }
      }

      function killLoadedPins() {
        // Remove the pins loaded after clicking on a pin
        activepinmarkers.clearLayers();
        activeresearchmarker.clearLayers();
        activelanguagesmarker.clearLayers();
        activeoutreachmarker.clearLayers();
        activepeoplemarker.clearLayers();
      }

      function loadRelevantPins($pin_clicked, $layer, $category) {
        // When clicking on a pin, Read all the relavant data and then show the pins
        // Collect the bound to zoom map to the right level

        console.log("Pin clicked", $pin_clicked);
        // console.log($pin_clicked.target.options.data.coordinates.lat);
        if ($pin_clicked.hasOwnProperty("target")) {
          var $data = $pin_clicked.target.options.data;
        } else {
          var $data = $pin_clicked;
        }

        var $people = $data.people
          ? Array.isArray($data.people)
            ? $data.people
            : new Array($data.people)
          : false;
        var $language = $data.language
          ? Array.isArray($data.language)
            ? $data.language
            : new Array($data.language)
          : false;
        var $outreach = $data.outreach
          ? Array.isArray($data.outreach)
            ? $data.outreach
            : new Array($data.outreach)
          : false;
        var $research = $data.research
          ? Array.isArray($data.research)
            ? $data.research
            : new Array($data.research)
          : false;

        activeBoundsList = [];

        console.log(activeBoundsList);
        // // console.log('$people to load', $people);
        // // console.log('$language to load', $language);
        // // console.log('$outreach to load', $outreach);
        // // console.log('$research to load', $research);

        killLoadedPins();
        hideAllPins();

        loadTemplate($data, $category);
        $(".filter_map .search").click();
        $(".filter_wrapper").hide();

        // This should not be required.
        var $id = $data.id ? $data.id : $data["﻿id"];

        var $selected_pin_data = getCategoryFromID($id);

        addSingleMarkerToLayer(
          $data,
          $selected_pin_data.layer,
          $selected_pin_data.category,
          $pin_clicked
        );

        if ($people) {
          $.each($people, function ($index, $value) {
            getRelatedCategories("people", $value, function (data) {
              addSingleMarkerToLayer(
                data[0],
                peoplemarker,
                "people",
                $pin_clicked
              );
            });
          });
        }

        if ($language) {
          $.each($language, function ($index, $value) {
            getRelatedCategories("languages", $value, function (data) {
              addSingleMarkerToLayer(
                data[0],
                languagesmarker,
                "languages",
                $pin_clicked
              );
            });
          });
        }
        if ($outreach) {
          $.each($outreach, function ($index, $value) {
            getRelatedCategories("outreach", $value, function (data) {
              addSingleMarkerToLayer(
                data[0],
                outreachmarker,
                "outreach",
                $pin_clicked
              );
            });
          });
        }
        if ($research) {
          $.each($research, function ($index, $value) {
            getRelatedCategories("research", $value, function (data) {
              addSingleMarkerToLayer(
                data[0],
                researchmarker,
                "research",
                $pin_clicked
              );
            });
          });
        }

        setTimeout(function () {
          map.invalidateSize();
          map.fitBounds(activeBoundsList);
        }, 300);
      }

      function hideAllPins() {
        // Hide all the pins loaded on the map
        // map.removeLayer(researchmarker);
        // map.removeLayer(peoplemarker);
        // map.removeLayer(outreachmarker);
        // map.removeLayer(languagesmarker);
        researchmarker.clearLayers();
        peoplemarker.clearLayers();
        outreachmarker.clearLayers();
        languagesmarker.clearLayers();
      }

      function getRelatedCategories(category, id, callback) {
        $.ajax({
          type: "GET",
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          url: "/api?category=" + category + "&&id=" + id,
          success: function (data) {
            callback(data);
          },
        });
      }

      function getURL(archive) {
        var split = archive.split("http");
        var link = "http" + split[1];
        return link.trim();
      }

      function getDescription(archieve) {
        // GET DESCRIPTTION FROM TEXT
        var split = archieve.split("http");
        var desc = split[0] + "http" + split[1];
        console.log(desc);
        return desc.trim();
      }

      function bindActions() {
        $(".filter_map .research_list ul li").on("click", function () {
          $(".filter_map .research_list ul li").removeClass("active");
          $(this).addClass("active");
          if ($(this).hasClass("research")) {
            map.removeLayer(peoplemarker);
            map.removeLayer(outreachmarker);
            map.removeLayer(languagesmarker);
            !map.hasLayer(researchmarker) ? map.addLayer(researchmarker) : "";
            $(".research_list .default").text(
              "Over 320 research projects supported by CoEDL, including theses, seed funding and major projects led by Centre investigators, located by research site"
            );
          }
          if ($(this).hasClass("languages")) {
            map.removeLayer(researchmarker);
            map.removeLayer(outreachmarker);
            map.removeLayer(peoplemarker);
            !map.hasLayer(languagesmarker) ? map.addLayer(languagesmarker) : "";
            $(".research_list .default").text(
              "The over 270 people who worked with the Centre as investigators, students, professional staff and other partners, as located in late 2022"
            );
          }
          if ($(this).hasClass("people")) {
            map.removeLayer(languagesmarker);
            map.removeLayer(outreachmarker);
            map.removeLayer(researchmarker);
            !map.hasLayer(peoplemarker) ? map.addLayer(peoplemarker) : "";
            $(".research_list .default").text(
              "The over 210 languages and communities CoEDL connected with through Centre research, as located at the site of the research conducted"
            );
          }
          if ($(this).hasClass("outreach")) {
            map.removeLayer(languagesmarker);
            map.removeLayer(peoplemarker);
            map.removeLayer(researchmarker);
            !map.hasLayer(outreachmarker) ? map.addLayer(outreachmarker) : "";
            $(".research_list .default").text(
              "A selection of the more than 600 CoEDL Outreach activities, including public lectures, workshops, briefings, media appearances, resources and more "
            );
          }
          if ($(this).hasClass("all")) {
            map.addLayer(peoplemarker);
            map.addLayer(outreachmarker);
            map.addLayer(languagesmarker);
            map.addLayer(researchmarker);
            $(".research_list .default").text(
              "Our data on people, languages, research and outreach activities CoEDL connected with"
            );
          }
        });

        if (window.location.href.indexOf("changeDisplay") > -1) {
          $("#check").prop("checked", false);
        }

        $(".switch-button-area.view").on("click", function () {
          $("#check").prop(
            "checked",
            $("#check").prop("checked") == true ? false : true
          );
          if (!$("#check").is(":checked")) {
            if (map && map.remove) {
              map.off();
              map.remove();
            }
            $("#filter_list").css("display", "block");
            $("#filter_wrapper").css("display", "block");
            $(".header .nav").addClass("list");
            // $('#map').addClass('slide_open');

            $(".filter_map .research_list").css("visibility", "hidden");
          } else {
            initialiseMap();
            loadMarkers();
            initialiseLayers();
            $("#filter_list").css("display", "none");
            $("#map").removeClass("slide_open");
            $(".filter_map .research_list").css("visibility", "visible");
            $(".header .nav").removeClass("list");
          }
        });
      }

      if (!$("#check").is(":checked")) {
        if (map && map.remove) {
          map.off();
          map.remove();
        }
        $("#filter_list").css("display", "block");
        $("#filter_wrapper").css("display", "block");
        // $('#map').addClass('slide_open');

        $(".filter_map .research_list").css("visibility", "hidden");
      }

      function getCategoryFromID($id) {
        var $firstCharacter = $id.charAt(0);
        var $return_data = [];
        switch ($firstCharacter) {
          case "P":
            $return_data["category"] = "people";
            $return_data["layer"] = activepeoplemarker;
            return $return_data;
            break;
          case "L":
            $return_data["category"] = "languages";
            $return_data["layer"] = activelanguagesmarker;
            return $return_data;
            break;
          case "O":
            $return_data["category"] = "outreach";
            $return_data["layer"] = activeoutreachmarker;
            return $return_data;
            break;
          case "R":
            $return_data["category"] = "research";
            $return_data["layer"] = activeresearchmarker;
            return $return_data;
            break;

          default:
            break;
        }
      }

      $(document).on("click", ".related_category_item li", function () {
        var information = $(this).data("info");
        var category = $(this).attr("category");
        hideAllPins();
        var $layer = category + "marker";
        loadRelevantPins(information, $layer, category);
      });
      $(document).on("click", ".list_results_generated li", function () {
        var information = $(this).data("info");
        var category = $(this).attr("category");
        // loadTemplate(information, category);
        hideAllPins();
        var $layer = category + "marker";
        loadRelevantPins(information, $layer, category);
      });
    }
  }
}
