"use strict";

export class Connections {
  constructor() {
    this.name = "connections";
    console.log("%s module", this.name.toLowerCase());

    $(".filter_map .search").on("click", function() {
      $("#filter_list").css("display", "block");
      $(".filter_wrapper").show();
    });
    $(".close_section .close").on("click", function() {
      $("#filter_list").css("display", "none");
    });
    $(".list_of_categories ul li").on("click", function() {
      $(".list_of_categories ul li").removeClass("active");
      $(this).addClass("active");
      var categories = $(this)
        .attr("class")
        .split(" ");
      var category = categories[0];
      var id = null;
      if (category === "research") {
        $(".filter_wrapper .results").css("background-color", "#1C5057");
      } else if (category === "languages") {
        $(".filter_wrapper .results").css("background-color", "#760E1F");
      } else if (category === "people") {
        $(".filter_wrapper .results").css(
          "background-color",
          "rgba(138,124,43,1)"
        );
      } else if (category === "outreach") {
        $(".filter_wrapper .results").css("background-color", "#CF5831");
      }
      callAPI(category);
    });
    $(".list_of_categories ul li a").on("click", function(e) {
      e.preventDefault();
    });

    $(".list_results_generated ul li").on("click", function() {
      if ($("#check").is(":checked") || $(window).innerWidth() < 767) {
        $(".filter_wrapper").hide();
        $(".searched_results").css("display", "block");
      }
      $(".searched_results").css("display", "block");
    });
    $(".output_results .container .close").on("click", function() {
      $(".searched_results").css("display", "none");
    });

    $(".results .input_section .search").on(
      "input propertychange paste",
      function() {
        var search_text = $(".search")
          .val()
          .toLowerCase();
        $(".list_results_generated ul li").each(function() {
          if (
            $(this)
              .text()
              .toLowerCase()
              .indexOf(search_text) != -1
          ) {
            $(this).show();
          } else {
            $(this).hide();
          }
        });
      }
    );
  }
}

// call API for the category : THE LIST CARRIES THE DATA ITSELF SO NO NEED TO CALL API EVERYTIME WITH ID

function callAPI(category) {
  $.ajax({
    type: "GET",
    contentType: "application/json; charset=utf-8",
    dataType: "json",
    url: "/api?category=" + category + "&&id=",
    success: function(data) {
      $(".list_results_generated ul li").remove();
      //data = unescape(encodeURIComponent(data));
      $.each(data, function(i, v) {
        if (category == "research") {
          var li = $("<li/>").html(v["research_list"]);
          li.attr("data-info", JSON.stringify(v));
          li.attr("category", category);
          $(".list_results_generated ul").append(li);
        } else if (category == "languages") {
          var li = $("<li/>").html(v["languagename"]);
          li.attr("data-info", JSON.stringify(v));
          li.attr("category", category);
          $(".list_results_generated ul").append(li);
        } else if (category == "people") {
          var li = $("<li/>").html(v["display_name"]);
          li.attr("data-info", JSON.stringify(v));
          li.attr("category", category);
          $(".list_results_generated ul").append(li);
        } else if (category == "outreach") {
          var li = $("<li/>").html(v["outreach_list_display"]);
          //console.log(encodeURIComponent(v));
          li.attr("data-info", JSON.stringify(v));
          li.attr("category", category);
          $(".list_results_generated ul").append(li);
        }
      });
      $(".list_results_generated ul li")
        .sort(function(a, b) {
          var aText = $(a)
              .text()
              .toLowerCase(),
            bText = $(b)
              .text()
              .toLowerCase();
          return aText < bText ? -1 : aText > bText ? 1 : 0;
        })
        .appendTo(".list_results_generated ul");
      
    },
  });
}
