"use strict";

export class About {
  constructor() {
    this.name = "about";
    console.log("%s module", this.name.toLowerCase());
    $(".image_slider_and_work .works_slider_images").slick({
      autoplay: true,
      infinite: true,
      adaptiveHeight: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 2000,
      prevArrow: '<span class="icon-arrow-left"></span',
      nextArrow: '<span class="icon-arrow-right"></span',
    });

    $(".image_column .blue_text_images").slick({
      autoplay: true,
      infinite: true,
      fade: true,
      arrows: false,
      draggable: false,
      infinite: true,
      pauseOnHover: false,
      swipe: false,
      touchMove: false,
      speed: 2500,
      autoplaySpeed: 5000,
      adaptiveHeight: false,
    });
    $(".image_column .red_text_images").slick({
      autoplay: true,
      infinite: true,
      fade: true,
      arrows: false,
      draggable: false,
      infinite: true,
      pauseOnHover: false,
      swipe: false,
      touchMove: false,
      speed: 2500,
      autoplaySpeed: 6000,
      adaptiveHeight: false,
    });
    $(".image_column .orange_text_images").slick({
      autoplay: true,
      infinite: true,
      fade: true,
      arrows: false,
      draggable: false,
      infinite: true,
      pauseOnHover: false,
      swipe: false,
      touchMove: false,
      speed: 2500,
      autoplaySpeed: 6500,
      adaptiveHeight: false,
    });
    $(".image_column .olive_text_images").slick({
      autoplay: true,
      infinite: true,
      fade: true,
      arrows: false,
      draggable: false,
      infinite: true,
      pauseOnHover: false,
      swipe: false,
      touchMove: false,
      speed: 2500,
      autoplaySpeed: 7000,
      adaptiveHeight: false,
    });

    $(".descriptions a.link_highlight").on("mouseenter", function() {
      $(".descriptions a.link_highlight span").remove();
      $(".descriptions a.link_highlight").append(
        '<span class="icon-arrow-white"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>'
      );
    });
    $(".descriptions a.link_highlight").on("mouseleave", function() {
      $(".descriptions a.link_highlight span").remove();
      $(".descriptions a.link_highlight").append(
        '<span class="icon-arrow-right"></span>'
      );
    });
  }
}
