'use strict';

export  class Header {
  constructor() {
    this.name = 'header';
    console.log('%s module', this.name.toLowerCase());
    var page_type= $('body').attr('page-type');
    $(`.nav a[id=${page_type}`).addClass('is-active');
     $('.nav a').on('click', function(){
      $('.nav a').removeClass('is-active');
      $(this).addClass('is-active');
    })
    $('.mobile-nav span').on('click', function(){
        if($('.mobile-only').hasClass('hidden')){
          $('.mobile-only').removeClass('hidden');
          $('.mobile-nav span').removeClass('icon-lines').addClass('icon-cross');  
          $("body").css('overflow', 'hidden');
        }else{
          $('.mobile-only').addClass('hidden');
           $('.mobile-nav span').removeClass('icon-cross').addClass('icon-lines');
           $("body").css('overflow', 'visible');
        }
    })
  }
}
